
import React, { useState } from 'react';

import LogoutPopup from '@fuse/components/Logoutpopup/logoutpopup';

export const DepartmentMenu = [
    // {

    //     'id'      : 'Department',
    //     'title'   : 'Department',
    //     'type'    : 'collapse',
    // },

    {
        'id': 'allPatientSummary',
        'title': 'All Patient Summary',
        'type': 'item',
        'url': '/pages/Allocatedbedanddevice'
    },

    {
        'id': 'departmentProfile',
        'title': 'Department Profile',
        'type': 'item',
        'url': '/pages/DepartmentProfile'
    },
    {
        'id': 'doctorRegistration',
        'title': 'Doctor Registration',
        'type': 'item',
        'url': '/pages/DoctorRegistration'
    },
    {
        'id': 'Doctor Management',
        'title': 'Doctor Management',
        'type': 'item',
        'url': '/pages/DoctorDetails'
    },
    {
        'id': 'addDeviceToBed',
        'title': 'Add Device To Bed',
        'type': 'item',
        'url': '/pages/addbed'
    },
    {
        'id': 'bedDeviceDetails',
        'title': 'Bed & Device Details',
        'type': 'item',
        'url': '/pages/Beddetails'
    },
    {
        'id': 'patientRegistration',
        'title': 'Patient Registration',
        'type': 'item',
        'url': '/pages/patientForm'
    },
   
];

export const All=[  
            
                    // {
                    //     'id'      : 'Organization',
                    //     'title'   : 'Organization',
                    //     'type'    : 'collapse',
                        
                    // },                        
                            {
                                'id'   : 'dashboard',
                                'title': 'Organization Profile',
                                'type' : 'item',
                                'url'  : '/pages/Companyprofile'
                            },

                            {
                                'id'   : 'dashboard',
                                'title': 'Device List',
                                'type' : 'item',
                                'url'  : '/pages/DeviceDetails'
                            },
                            {
                                'id'   : 'dashboard',
                                'title': 'Add New Device',
                                'type' : 'item',
                                'url'  : '/pages/Adddevice'
                            },
                            {
                                'id'   : 'dashboard',
                                'title': 'Create Department',
                                'type' : 'item',
                                'url'  : '/pages/CreateDepartment'
                            },      
                            {
                                'id'   : 'dashboard',
                                'title': 'Departments Lists',
                                'type' : 'item',
                                'url'  : '/pages/DepartmentsLists'
                            },  
                                                
                ];
            

export const SuperAdmin=[

// {
//     'id'      : 'SuperAdmin',
//     'title'   : 'SuperAdmin',
//     'type'    : 'collapse',
// },
        {
            'id'   : 'dashboard',
            'title': 'Create Organization',
            'type' : 'item',
            'url'  : '/pages/SuperAdmin'
        },
        
        {
            'id'   : 'dashboard',
            'title': 'Organization Lists',
            'type' : 'item',
            'url'  : '/pages/CompanyLists'
        },
        {
            'id'   : 'dashboard',
            'title': 'Add Device',
            'type' : 'item',
            'url'  : '/pages/AddNewDevice'
        },       
    ]

export const Doctor=[

    {
        'id'      : 'Doctor',
        'title'   : 'Doctor',
        'type'    : 'collapse',
    
    },
            {
               'id'   : 'dashboard',
            'title': 'All Patient Summary',
            'type' : 'item',
            'url'  : '/pages/Allocatedbedanddevice'
            },
        ]  
        
        
       
export const navigationItems=[
  
   
]